<template>
  <v-data-table
    v-bind="$attrs"
    must-sort
    fixed-header
    show-expand
    :loading="loading"
    :headers="headers"
    :items="jobs"
    no-data-text="No pending jobs"
    item-key="id"
    :options.sync="options"
    :server-items-length="totalItems"
    :footer-props="{
      itemsPerPageOptions: [ 10, 25, 50, 100 ],
      itemsPerPageText: `Jobs ${ $t('per page') }:`,
      showFirstLastPage: true,
    }"
  >
    <template #top>
      <v-row
        justify="space-between"
        class="pt-3 px-3 mb-n3 "
      >
        <v-col md="3">
          <search-bar :value.sync="options.search" />
        </v-col>
        <v-spacer />
        <v-col md="2">
          <table-filter
            :value.sync="options.filter.queue"
            :choices="[ 'default', 'lab_results', 'webhooks' ]"
            label="Queue"
            icon="fal fa-book-bookmark"
            multiple
          />
        </v-col>
      </v-row>
    </template>
    <template #expanded-item="{ item }">
      <td :colspan="headers.length - 1">
        <v-row>
          <v-col
            cols="1"
            class="mt-4"
          >
            <v-btn
              small
              icon
              tile
              color="info"
              @click="$clipboard(item.payload)"
            >
              <v-icon small>
                mdi-content-copy mdi-flip-h
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="11"
          >
            <vue-json-pretty
              :data="JSON.parse(item.payload)"
              class="my-3"
              style="font-size:0.8em; overflow-wrap:anywhere;"
            />
          </v-col>
        </v-row>
      </td>
    </template>
    <template #item.queue="{ item }">
      <v-chip
        small
        v-text="item.queue"
      />
    </template>
    <template #item.payload="{ item }">
      {{ JSON.parse(item.payload).displayName }}
    </template>
    <template #item.reserved_at="{ item }">
      <span class="text-caption">
        {{ item.reserved_at | moment('L LT') }}
      </span>
    </template>
    <template #item.available_at="{ item }">
      <span class="text-caption">
        {{ item.available_at | moment('L LT') }}
      </span>
    </template>
    <template #item.created_at="{ item }">
      <span class="text-caption">
        {{ item.created_at | moment('L LT') }}
      </span>
    </template>
    <template #footer.prepend>
      <v-select
        v-model="interval"
        dense
        :items="[
          15,30,60,120
        ]"
        class="v-data-footer__select ml-2 mt-4"
      >
        <template #prepend>
          <span class="text-caption mt-n4">Refresh every</span>
        </template>
        <template #append-outer>
          <span class="text-caption mt-n4">seconds</span>
          <v-progress-circular
            class="ml-2 mt-n4"
            color="grey darken-1"
            :rotate="-90"
            :size="20"
            :width="3"
            :value="100 - (100 * (time_left / (interval -1)))"
          />
        </template>
      </v-select>
      <v-spacer />
    </template>
  </v-data-table>
</template>
<script>
  import 'vue-json-pretty/lib/styles.css';

  export default {
    components: {
      VueJsonPretty: () => import('vue-json-pretty'),
      SearchBar: () => import('@/components/tables/filters/SearchBar.vue'),
      TableFilter: () => import('@/components/tables/filters/TableFilter.vue'),
    },
    props: {
      search: {
        type: String,
        default: null,
      },
    },
    metaInfo: {
      title: 'Jobs',
    },
    data () {
      return {
        right: false,
        loading: false,
        options: {
          filter: this.$route.query,
          search: null,
          sortBy: ['reserved_at'],
          sortDesc: [true],
        },
        totalItems: null,
        headers: [
          {
            value: 'id',
            text: 'ID',
          },
          {
            value: 'queue',
            text: 'Queue',
          },
          {
            value: 'payload',
            text: 'Job',
          },
          {
            value: 'attempts',
            text: 'Attempts',
          },
          {
            value: 'reserved_at',
            text: 'Reserved',
          },
          {
            value: 'available_at',
            text: 'Available',
          },
          {
            value: 'created_at',
            text: 'Created',
          },
          {
            sortable: false,
            align: 'right',
            value: 'actions',
          },
        ],
        jobs: [],
        timer: null,
        time_counter: null,
        interval: 30,
        time_left: this.interval - 1,
      }
    },
    watch: {

      options: {
        deep: true,
        handler () {
          this.fetchJobs()
        },
      },
      interval () {
        this.startTimer()
      },
    },
    mounted() {
      this.startTimer()
    },
    methods: {
      startTimer () {
        clearInterval(this.timer)
        this.timer = setInterval(this.fetchJobs, this.interval * 1000)

        clearInterval(this.time_counter)
        var counter = this.interval - 1
        this.time_counter = setInterval(function() {
          if (counter < 1) {
            counter = this.interval - 1
          }
          this.time_left = counter--
        }.bind(this), 1000)
      },
      fetchJobs () {
        this.loading = true
        const promise = this.axios.get('admin/jobs', {
          params: {
            ...{
              page: this.options.page,
              count: this.options.itemsPerPage,
              sort: this.options.sortBy[0],
              order: this.options.sortDesc[0] ? 'desc' : 'asc',
              search: this.options.search,
            },
            ...this.$route.query,
          },
        })

        return promise.then((response) => {
          this.$set(this.options, 'page', response.data.current_page)
          this.$set(this.options, 'itemsPerPage', Number(response.data.per_page))
          this.totalItems = response.data.total
          this.jobs = response.data.data
        }).catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        }).finally(() => {
          this.loading = false
        })
      },
    },
    beforeRouteLeave (to, from, next) {
      clearInterval(this.timer)
      next()
    },
  }
</script>
